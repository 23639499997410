import React, { useEffect, useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import Logo from 'components/atoms/Logo';
import SelectLanguage from 'components/SelectLanguage/SelectLanguage';
import Hamburger from 'components/Hamburger/Hamburger';
import { navigationAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';
import {
  StyledNavigationWrapper,
  StyledSiteName,
  StyledLogoWrapper,
  StyledHamburger,
  StyledHamburgerLine,
  StyledMenuWrapper,
  StyledMenuLink,
  StyledSelectLanguage,
  StyledAnchorTag,
  StyledMenuExternalLink,
} from './StyledNavigation';

const uuidv4 = require('uuid/v4');

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const Navigation = ({ relatedPages = false, currentLocale }) => {
  const data = useStaticQuery(
    graphql`
      query {
        navPl: strapiNavigation(locale: { eq: "pl" }) {
          main_nav
        }
        navEn: strapiNavigation(locale: { eq: "en" }) {
          main_nav
        }
      }
    `
  );

  let nav;
  if (currentLocale === 'pl') nav = data.navPl;
  else if (currentLocale === 'en') nav = data.navEn;

  const { main_nav: mainNav } = nav;

  const location = useLocation();

  const [lastY, setLastY] = useState(0);
  const [isScrollDown, setNavHidden] = useState(false);
  const [isNavbarOnTop, setScrolledToTop] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    initial: false,
    clicked: null,
  });

  const links = useRef([]);

  const handleLinkAndScroll = elem => {
    if (location.pathname === '/pl/' || location.pathname === '/en/') {
      window.location.hash = elem;
      scroller.scrollTo(elem, {
        duration: 1000,
        delay: 100,
        smooth: true,
        hash: elem,
      });
    } else {
      navigate(`/`, { state: { scrollToSection: elem }, hash: { elem } });
    }
  };

  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1800);
  };

  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const yPosition = window.scrollY;
      const isScrollingUp = lastY > yPosition;
      setNavHidden(!isScrollingUp && yPosition > 0);
      setLastY(yPosition);
      setScrolledToTop(yPosition < 30);
    };

    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [lastY]);

  useEffect(() => {
    navigationAnimation(links);
  }, []);

  return (
    <header>
      <StyledNavigationWrapper
        scrollDirection={isScrollDown}
        scrolledToTop={isNavbarOnTop}
      >
        <StyledSiteName>
          <StyledLogoWrapper
            to="/"
            onClick={e => {
              e.preventDefault();
              navigate('/');
            }}
          >
            <Logo width="15rem" />
          </StyledLogoWrapper>
        </StyledSiteName>
        <StyledMenuWrapper>
          {mainNav.map((item, index) => {
            const { strapi_component: componentName } = item;
            switch (componentName) {
              case 'navigation.strona-dynamiczna': {
                const { dynamic_page: dynamicPage } = item;
                return (
                  <StyledMenuLink
                    to={`/${dynamicPage.slug}`}
                    key={uuidv4()}
                    ref={el => {
                      links.current[index] = el;
                    }}
                  >
                    {dynamicPage.title}
                  </StyledMenuLink>
                );
              }
              case 'navigation.strona-statyczna': {
                const { static_page: staticPage } = item;

                return (
                  <StyledMenuExternalLink
                    to={staticPage.url}
                    key={uuidv4()}
                    ref={el => {
                      links.current[index] = el;
                    }}
                  >
                    {staticPage.title}
                  </StyledMenuExternalLink>
                );
              }
              case 'navigation.sekcja': {
                if (!item.all_section) {
                  return null;
                }

                return (
                  <StyledMenuLink
                    ref={el => {
                      links.current[index] = el;
                    }}
                    to={
                      item.all_section.hash
                        ? `/#${item.all_section.hash}`
                        : `/#${item.all_section.name}`
                    }
                    onClick={() =>
                      handleLinkAndScroll(
                        item.all_section.hash
                          ? `#${item.all_section.hash}`
                          : item.all_section.name
                      )
                    }
                    key={uuidv4()}
                  >
                    {item.all_section.name}
                  </StyledMenuLink>
                );
              }
              default:
                return false;
            }
          })}
          <StyledAnchorTag
            href="https://sklep.moee.pl/"
            target="_blank"
            rel="noreferrer"
          >
            {currentLocale === 'pl' ? 'Sklep' : 'Shop'}
          </StyledAnchorTag>
        </StyledMenuWrapper>
        <StyledHamburger disabled={disabled} onClick={handleMenu}>
          <StyledHamburgerLine />
          <StyledHamburgerLine />
          <StyledHamburgerLine />
        </StyledHamburger>
        <StyledSelectLanguage>
          <SelectLanguage relatedPages={relatedPages} />
        </StyledSelectLanguage>
      </StyledNavigationWrapper>
      <Hamburger
        relatedPages={relatedPages}
        state={state}
        handleMenu={handleMenu}
        disabled={disabled}
      />
    </header>
  );
};

export default withLocale(Navigation);
